<template>
  <div class="contact">
    <div class="" >
      <img src="../assets/contact/top.png" alt="" srcset="">
    </div>
    <div class="flex p-16 my-10">
      <div class="flex-1 mr-8 flex items-center"><img src="../assets/contact/map.png" alt="" srcset=""></div>
      <div class="flex-1 text-left">
        <div style="margin-bottom:60px;">
          <span style="font-size: 48px;">{{ $t('message.connect.title') }}</span>
          <span style="color:#B029234D;font-size: 36px;line-height: 42px;">CONTACT US</span>
        </div>
        <div style="font-size: 20px; line-height:50px;">
          <div>
            {{ $t('message.connect.address') }}
          </div>
          <div>
            {{ $t('message.connect.tel') }}
          </div>
          <!-- <div>
            传真：www.hotanzn.com
          </div> -->
          <div>
            {{ $t('message.connect.email') }}
          </div>
        </div>
        <div>
          <!-- <div style="font-size: 32px; margin-top:80px;">
            您的留言是我们缺少的重要
          </div>
          <div style="font-size: 24px; margin-top:10px;">
            Your message is the most important thing we lack
          </div> -->
        </div>
      </div>
    </div>
    <!-- <div class="part2 pt-20 pb-5 mb-16" style="background: #F2F0F0;">
      <div class="divide" style="">
        <div class="" style="color:#000000FF;font-size: 48px;z-index: 1;">给我们留言</div>
        <div class=" tracking-wider" style="color: #B029234D;font-size: 48px;margin-top:-45px;">LEAVE US A MESSAGE</div>
      </div>
      <div class="my-20 px-20">
        <el-form ref="form" :model="form" label-width="80px">
          <el-row>
            <el-col :span="8">
              <el-form-item label="姓名：" >
                <el-input v-model="form.name" placeholder="请输入您的姓名"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="电话：">
                <el-input v-model="form.mobile" placeholder="请输入您的电话"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="公司：">
                <el-input v-model="form.company" placeholder="请输入您的公司名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="留言">
            <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4}" v-model="form.message" placeholder="我们能为您做什么"></el-input>
          </el-form-item>
          <el-form-item class="text-left">
            <el-button style="width: 200px;border-radius: 6px 6px 6px 6px;background: #B02923;color: #FFFFFF;" @click="onSubmit">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  data(){
    return{
      form:{}
    }
  },
  methods:{
    onSubmit(){},
  }
}
</script>
